import React, { Fragment } from "react";

const Icons = () => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <h3 className="margin-bottom-20">Icons</h3>
        <ul className="list-inline">
          <li>
            <a href="#fontello">
              <strong>Fontello</strong>
            </a>
          </li>
        </ul>
        <hr />
        <p className="full-width margin-bottom-20">
          We follow a 6px baseline grid for achieving a vertical rhythm on all
          block-level elements in the app. With the 2015&nbsp;
          <a href="http://blog.mailchimp.com/redesigning-mailchimps-app-navigation/">
            redesign of our navigation
          </a>&nbsp; we moved the app to the lighter, friendlier, and more
          legible&nbsp;
          <a href="http://www.google.com/fonts/specimen/Open+Sans">
            Open Sans
          </a>.
        </p>
      </div>
    </div>

    <div className="row margin-bottom-20" id="fontello">
      <div className="col-xs-12">
        <blockquote>
          <h4>
            <i>Fontello</i>
          </h4>
        </blockquote>
      </div>

      <div className="icon-list col-xs-12">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-user" />
            <span className="i-name">icon-user</span>&nbsp;
            <span className="small i-code">0xe800</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-mobile" />
            <span className="i-name">icon-mobile</span>&nbsp;
            <span className="small i-code">0xe801</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-share" />
            <span className="i-name">icon-share</span>&nbsp;
            <span className="small i-code">0xe802</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-address-book" />
            <span className="i-name">icon-address-book</span>&nbsp;
            <span className="small i-code">0xe804</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-airplane" />
            <span className="i-name">icon-airplane</span>&nbsp;
            <span className="small i-code">0xe805</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-hotel" />
            <span className="i-name">icon-hotel</span>&nbsp;
            <span className="small i-code">0xe806</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-shake-hands" />
            <span className="i-name">icon-shake-hands</span>&nbsp;
            <span className="small i-code">0xe807</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-write" />
            <span className="i-name">icon-write</span>&nbsp;
            <span className="small i-code">0xe809</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-arrow-left" />
            <span className="i-name">icon-arrow-left</span>&nbsp;
            <span className="small i-code">0xe80a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-arrow-right" />
            <span className="i-name">icon-arrow-right</span>&nbsp;
            <span className="small i-code">0xe80b</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-arrow-up" />
            <span className="i-name">icon-arrow-up</span>&nbsp;
            <span className="small i-code">0xe80c</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-arrow-down" />
            <span className="i-name">icon-arrow-down</span>&nbsp;
            <span className="small i-code">0xe80d</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-open" />
            <span className="i-name">icon-open</span>&nbsp;
            <span className="small i-code">0xe80e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-locator" />
            <span className="i-name">icon-locator</span>&nbsp;
            <span className="small i-code">0xe80f</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-calendar" />
            <span className="i-name">icon-calendar</span>&nbsp;
            <span className="small i-code">0xe84d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-hide" />
            <span className="i-name">icon-hide</span>&nbsp;
            <span className="small i-code">0xe811</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-close" />
            <span className="i-name">icon-close</span>&nbsp;
            <span className="small i-code">0xe812</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-search" />
            <span className="i-name">icon-search</span>&nbsp;
            <span className="small i-code">0xe813</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-lock" />
            <span className="i-name">icon-lock</span>&nbsp;
            <span className="small i-code">0xe814</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-dollar" />
            <span className="i-name">icon-dollar</span>&nbsp;
            <span className="small i-code">0xe815</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-timer" />
            <span className="i-name">icon-timer</span>&nbsp;
            <span className="small i-code">0xe816</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-valid-circle" />
            <span className="i-name">icon-valid-circle</span>&nbsp;
            <span className="small i-code">0xe817</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-error" />
            <span className="i-name">icon-error</span>&nbsp;
            <span className="small i-code">0xe818</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-archive" />
            <span className="i-name">icon-archive</span>&nbsp;
            <span className="small i-code">0xe819</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-alipay" />
            <span className="i-name">icon-alipay</span>&nbsp;
            <span className="small i-code">0xe81a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-flip-left" />
            <span className="i-name">icon-edit-flip-left</span>&nbsp;
            <span className="small i-code">0xe81b</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-flip-right" />
            <span className="i-name">icon-edit-flip-right</span>&nbsp;
            <span className="small i-code">0xe81c</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-credit-card" />
            <span className="i-name">icon-credit-card</span>&nbsp;
            <span className="small i-code">0xe81d</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-picture" />
            <span className="i-name">icon-picture</span>&nbsp;
            <span className="small i-code">0xe81e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-camera" />
            <span className="i-name">icon-camera</span>&nbsp;
            <span className="small i-code">0xe81f</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit" />
            <span className="i-name">icon-edit</span>&nbsp;
            <span className="small i-code">0xe820</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-folder-open" />
            <span className="i-name">icon-folder-open</span>&nbsp;
            <span className="small i-code">0xe821</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-horizontal" />
            <span className="i-name">icon-edit-horizontal</span>&nbsp;
            <span className="small i-code">0xe822</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-vertical" />
            <span className="i-name">icon-edit-vertical</span>&nbsp;
            <span className="small i-code">0xe823</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-chat" />
            <span className="i-name">icon-chat</span>&nbsp;
            <span className="small i-code">0xe824</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-zoom-in" />
            <span className="i-name">icon-edit-zoom-in</span>&nbsp;
            <span className="small i-code">0xe825</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-truck" />
            <span className="i-name">icon-truck</span>&nbsp;
            <span className="small i-code">0xe826</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-edit-zoom-out" />
            <span className="i-name">icon-edit-zoom-out</span>&nbsp;
            <span className="small i-code">0xe827</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-check" />
            <span className="i-name">icon-check</span>&nbsp;
            <span className="small i-code">0xe828</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-info" />
            <span className="i-name">icon-info</span>&nbsp;
            <span className="small i-code">0xe829</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-photo-fail" />
            <span className="i-name">icon-photo-fail</span>&nbsp;
            <span className="small i-code">0xe82a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-photo-success" />
            <span className="i-name">icon-photo-success</span>&nbsp;
            <span className="small i-code">0xe82d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-profile" />
            <span className="i-name">icon-profile</span>&nbsp;
            <span className="small i-code">0xe82e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-hotel-services" />
            <span className="i-name">icon-hotel-services</span>&nbsp;
            <span className="small i-code">0xe82f</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-flight" />
            <span className="i-name">icon-flight</span>&nbsp;
            <span className="small i-code">0xe830</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-car-rental" />
            <span className="i-name">icon-car-rental</span>&nbsp;
            <span className="small i-code">0xe831</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-declare" />
            <span className="i-name">icon-declare</span>&nbsp;
            <span className="small i-code">0xe838</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-family-visit" />
            <span className="i-name">icon-family-visit</span>&nbsp;
            <span className="small i-code">0xe839</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-itinerary" />
            <span className="i-name">icon-itinerary</span>&nbsp;
            <span className="small i-code">0xe83a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-applicant-doc" />
            <span className="i-name">icon-applicant-doc</span>&nbsp;
            <span className="small i-code">0xe860</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-applicant" />
            <span className="i-name">icon-applicant</span>&nbsp;
            <span className="small i-code">0xe861</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-assist" />
            <span className="i-name">icon-assist</span>&nbsp;
            <span className="small i-code">0xe862</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-character" />
            <span className="i-name">icon-character</span>&nbsp;
            <span className="small i-code">0xe863</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-children-doc" />
            <span className="i-name">icon-children-doc</span>&nbsp;
            <span className="small i-code">0xe864</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-children" />
            <span className="i-name">icon-children</span>&nbsp;
            <span className="small i-code">0xe865</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-contact" />
            <span className="i-name">icon-contact</span>&nbsp;
            <span className="small i-code">0xe866</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-delicate" />
            <span className="i-name">icon-delicate</span>&nbsp;
            <span className="small i-code">0xe867</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-education" />
            <span className="i-name">icon-education</span>&nbsp;
            <span className="small i-code">0xe868</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-embrace" />
            <span className="i-name">icon-embrace</span>&nbsp;
            <span className="small i-code">0xe869</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-entry" />
            <span className="i-name">icon-entry</span>&nbsp;
            <span className="small i-code">0xe86a</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-family" />
            <span className="i-name">icon-family</span>&nbsp;
            <span className="small i-code">0xe86b</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-health" />
            <span className="i-name">icon-health</span>&nbsp;
            <span className="small i-code">0xe86c</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-military" />
            <span className="i-name">icon-military</span>&nbsp;
            <span className="small i-code">0xe86d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-partner-doc" />
            <span className="i-name">icon-partner-doc</span>&nbsp;
            <span className="small i-code">0xe86e</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-partner" />
            <span className="i-name">icon-partner</span>&nbsp;
            <span className="small i-code">0xe86f</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-property" />
            <span className="i-name">icon-property</span>&nbsp;
            <span className="small i-code">0xe870</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-terms" />
            <span className="i-name">icon-terms</span>&nbsp;
            <span className="small i-code">0xe871</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-travel" />
            <span className="i-name">icon-travel</span>&nbsp;
            <span className="small i-code">0xe872</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-twitter" />
            <span className="i-name">icon-twitter</span>&nbsp;
            <span className="small i-code">0xf099</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-facebook" />
            <span className="i-name">icon-facebook</span>&nbsp;
            <span className="small i-code">0xf09a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-paste" />
            <span className="i-name">icon-paste</span>&nbsp;
            <span className="small i-code">0xf0ea</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-suitcase" />
            <span className="i-name">icon-suitcase</span>&nbsp;
            <span className="small i-code">0xf0f2</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-weibo" />
            <span className="i-name">icon-weibo</span>&nbsp;
            <span className="small i-code">0xf18a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-graduation-cap" />
            <span className="i-name">icon-graduation-cap</span>&nbsp;
            <span className="small i-code">0xf19d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-qq" />
            <span className="i-name">icon-qq</span>&nbsp;
            <span className="small i-code">0xf1d6</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-wechat" />
            <span className="i-name">icon-wechat</span>&nbsp;
            <span className="small i-code">0xf1d7</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-heartbeat" />
            <span className="i-name">icon-heartbeat</span>&nbsp;
            <span className="small i-code">0xf21e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-balance-scale" />
            <span className="i-name">icon-balance-scale</span>&nbsp;
            <span className="small i-code">0xf24e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-id-card" />
            <span className="i-name">icon-id-card</span>&nbsp;
            <span className="small i-code">0xf2c3</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-en" />
            <span className="i-name">icon-en</span>&nbsp;
            <span className="small i-code">0xe8ec</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-cn" />
            <span className="i-name">icon-cn</span>&nbsp;
            <span className="small i-code">0xe8ed</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-instagram" />
            <span className="i-name">icon-instagram</span>&nbsp;
            <span className="small i-code">0xf16d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-paypal" />
            <span className="i-name">icon-paypal</span>&nbsp;
            <span className="small i-code">0xe803</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-phone" />
            <span className="i-name">icon-phone</span>&nbsp;
            <span className="small i-code">0xe808</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-linkedin-squared" />
            <span className="i-name">icon-linkedin-squared</span>&nbsp;
            <span className="small i-code">0xf30c</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-files" />
            <span className="i-name">icon-files</span>&nbsp;
            <span className="small i-code">0xe880</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-post" />
            <span className="i-name">icon-post</span>&nbsp;
            <span className="small i-code">0xe881</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-embassy" />
            <span className="i-name">icon-embassy</span>&nbsp;
            <span className="small i-code">0xe882</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-shipping" />
            <span className="i-name">icon-shipping</span>&nbsp;
            <span className="small i-code">0xe883</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-template" />
            <span className="i-name">icon-template</span>&nbsp;
            <span className="small i-code">0xEe849</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-agency" />
            <span className="i-name">icon-agency</span>&nbsp;
            <span className="small i-code">0xe885</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-online" />
            <span className="i-name">icon-online</span>&nbsp;
            <span className="small i-code">0xe886</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-time" />
            <span className="i-name">icon-time</span>&nbsp;
            <span className="small i-code">0xe887</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-delete" />
            <span className="i-name">icon-delete</span>&nbsp;
            <span className="small i-code">0xe88b</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-error-circle" />
            <span className="i-name">icon-error-circle</span>&nbsp;
            <span className="small i-code">0xe88d</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-promotion" />
            <span className="i-name">icon-promotion</span>&nbsp;
            <span className="small i-code">0xe82b</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-ocr" />
            <span className="i-name">icon-ocr</span>&nbsp;
            <span className="small i-code">0xe88C</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-invalid" />
            <span className="i-name">icon-invalid</span>&nbsp;
            <span className="small i-code">0xe88e</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-valid" />
            <span className="i-name">icon-valid</span>&nbsp;
            <span className="small i-code">0xe88f</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-github" />
            <span className="i-name">icon-github</span>&nbsp;
            <span className="small i-code">0xe892</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-setting" />
            <span className="i-name">icon-setting</span>&nbsp;
            <span className="small i-code">0xe84a</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-translate" />
            <span className="i-name">icon-translate</span>&nbsp;
            <span className="small i-code">0xe84b</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-consultation" />
            <span className="i-name">icon-consultation</span>&nbsp;
            <span className="small i-code">0xe84c</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-certificate" />
            <span className="i-name">icon-certificate</span>&nbsp;
            <span className="small i-code">0xe84e</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-laptop" />
            <span className="i-name">icon-laptop</span>&nbsp;
            <span className="small i-code">0xe84f</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-secure" />
            <span className="i-name">icon-secure</span>&nbsp;
            <span className="small i-code">0xe850</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-timeglass" />
            <span className="i-name">icon-timeglass</span>&nbsp;
            <span className="small i-code">0xe851</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-truck-right" />
            <span className="i-name">icon-truck-right</span>&nbsp;
            <span className="small i-code">0xe852</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-applicant-info" />
            <span className="i-name">icon-applicant-info</span>&nbsp;
            <span className="small i-code">0xe810</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-children-info" />
            <span className="i-name">icon-children-info</span>&nbsp;
            <span className="small i-code">0xe853</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-partner-material" />
            <span className="i-name">icon-partner-material</span>&nbsp;
            <span className="small i-code">0xe854</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            <i className="icon-children-material" />
            <span className="i-name">icon-children-material</span>&nbsp;
            <span className="small i-code">0xe855</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <i className="icon-book" />
            <span className="i-name">icon-book</span>&nbsp;
            <span className="small i-code">0xe856</span>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);
export default Icons;
